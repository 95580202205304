import request from '@/utils/request'


// 查询充值菜单列表
export function listRechargeMenu(query) {
  return request({
    url: '/recharge/rechargeMenus/list',
    method: 'get',
    params: query
  })
}

// 查询充值菜单分页
export function pageRechargeMenu(query) {
  return request({
    url: '/recharge/rechargeMenus/page',
    method: 'get',
    params: query
  })
}

// 查询充值菜单详细
export function getRechargeMenu(data) {
  return request({
    url: '/recharge/rechargeMenus/detail',
    method: 'get',
    params: data
  })
}

// 新增充值菜单
export function addRechargeMenu(data) {
  return request({
    url: '/recharge/rechargeMenus/add',
    method: 'post',
    data: data
  })
}

// 修改充值菜单
export function updateRechargeMenu(data) {
  return request({
    url: '/recharge/rechargeMenus/edit',
    method: 'post',
    data: data
  })
}

// 删除充值菜单
export function delRechargeMenu(data) {
  return request({
    url: '/recharge/rechargeMenus/delete',
    method: 'post',
    data: data
  })
}



export function changeIsOpen(data) {
  return request({
    url: '/recharge/rechargeMenus/changeIsOpen',
    method: 'post',
    data: data
  })
}